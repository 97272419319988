<template>
  <div class="select-container" @scroll.prevent @touchmove.prevent>
    <!-- 收货地址三级联动选项 start-->
    <section class="address" :class="{togeHeight:is_togeHeight}">
      <div class="address_slide_head border-bottom-1px">所在地区</div>
      <section class="title border-bottom-1px">
        <div class="area" @click.stop="provinceSelected" :class="[province_ac ? 'ac-color' : '']">
          {{Province?Province:'请选择'}}
        </div>
        <div class="area" @click.stop="citySelected" :class="[city_ac ? 'ac-color':'']" v-show="two_show">
          {{City?City:'请选择'}}
        </div>
        <div class="area" @click.stop="districtSelected" :class="area_ac ? 'ac-color':''" v-show="three_show">
          {{District?District:'请选择'}}
        </div>
        <div class="area" @click.stop="streetSelected" :class="street_ac ? 'ac-color':''" v-show="four_show">
          {{Street?Street:'请选择'}}
        </div>
        <div class="determine" v-show="showDeter" @click.stop="confirmAddress">确定</div>
      </section>
      <ul v-show="showProvince" class="proJuli" @touchstart.stop @touchmove.stop @touchend.stop>
        <li class="addList df alc jc-sb" v-for="(v,k) in province_list"
            @click.stop="getProvinceId(v.province_id, v.province_name, k)"
            :key="v.province_id"
            :class="{active : v.selected}"><span>{{v.province_name}}</span>
          <icon-svg v-if="v.selected" class="main-color w32" icon-class="yi_ok"/>
        </li>
      </ul>
      <ul v-show="showCity" class="citJuli" @touchstart.stop @touchmove.stop @touchend.stop>
        <li class="addList df alc jc-sb" v-for="(v,k) in showCityList" @click.stop="getCityId(v.city_id, v.city_name, k)"
            :key="v.city_id"
            :class="{active : v.selected}"><span>{{v.city_name}}</span>
          <icon-svg v-if="v.selected" class="main-color w32" icon-class="yi_ok"/>
        </li>
      </ul>
      <ul v-show="showDistrict" class="disJuli" @touchstart.stop @touchmove.stop @touchend.stop>
        <li class="addList df alc jc-sb" v-for="(v,k) in showDistrictList" @click.stop="getDistrictId(v.area_id, v.area_name, k)"
            :key="v.area_id"
            :class="{active : v.selected}"><span>{{v.area_name}}</span>
          <icon-svg v-if="v.selected" class="main-color w32" icon-class="yi_ok"/>
        </li>
      </ul>
      <ul v-show="showStreet" class="streetLi" @touchstart.stop @touchmove.stop @touchend.stop>
        <li class="addList df alc jc-sb" v-for="(v,k) in showStreetList" @click.stop="getStreetId(v.street_id, v.street_name, k)"
            :key="v.street_id"
            :class="{active : v.selected}"><span>{{v.street_name}}</span>
          <icon-svg v-if="v.selected" class="main-color w32" icon-class="yi_ok"/>
        </li>
      </ul>
    </section>
    <!-- 收货地址三级联动选项 end-->
    <div class="layout" :class="{layoutBg:is_layout}" @click.stop="closeAdd"></div>
  </div>
</template>
<script>
import {
  getProvinceList,
  getCityList,
  getAreaList,
  getStreetList
} from "@/services/my";

export default {
  props: {
    edit_date: '',
  },
  data() {
    return {
      is_layout: false,
      is_togeHeight: false,
      isBc: false, // 用于控制保存按钮高亮
      toggle: false, // 用于切换默认地址
      showDeter: false,
      province_ac: true,
      city_ac: false,
      area_ac: false,
      street_ac: false,
      two_show: false,
      three_show: false,
      four_show: false,
      userAddress: '',
      one_li_index: '', // 用于高亮子菜单
      two_li_Index: '',
      titleIndex: Number,
      showProvince: true, // 第一个li默认显示
      showCity: false, // 第二个li默认隐藏
      showDistrict: false, // 第三个li默认隐藏
      showStreet: false, // 第四个li默认隐藏
      showCityList: [],
      showDistrictList: [],
      showStreetList: [],
      province: '',
      city: '',
      district: '',
      GetProvinceId: 2,
      District: '',
      street: '',
      Street: '',
      Province: '',
      City: '',
      // v-for循环判断是否为当前
      selected: true,
      info: [], // 三级联动城市列表
      province_list: '',
      province_id: '',
      city_id: '',
      area_id: '',
      street_id: '',

    }
  },
  watch: {
    'edit_date'(newValue, oldValue) {
      this.$emit('update:edit_date', newValue);
      this.getParams();
    },
  },
  mounted() {
    this.getProvinceList()
  },
  methods: {
    async getParams() {
      // 初始化页面，如果是编辑地址的话，则
      let date = this.edit_date;
      this.province = date.province_id;
      this.city = date.city_id;
      this.district = date.area_id;
      this.street = date.street_id;
      this.two_show = true;// 控制第二个nav显示
      this.three_show = true;// 给第三个nav显示
      this.four_show = true;// 给第四个nav显示
      this.Province = date.area_string.split(' ')[0];//省
      this.City = date.area_string.split(' ')[1];//市
      this.District = date.area_string.split(' ')[2];//区
      this.Street = date.area_string.split(' ')[3];//街道
      this.showCityList = await this.getCityList(this.province);
      this.showDistrictList = await this.getAreaList(this.city);
      this.showStreetList = await this.getStreetList(this.district);
      // 高亮后台返回选中的地址，需要对应id
      this._newArr(this.province_list, this.province, 1)
      this._newArr(this.showCityList, this.city, 2)
      this._newArr(this.showDistrictList, this.district, 3)
      this._newArr(this.showStreetList, this.street, 4)
      this.showDeter = true

    },
    /**
     * 获取全国省份
     * @returns {Promise<void>}
     */
    async getProvinceList() {
      try {
        const res = await getProvinceList();
        if (res.code === 0) {
          const data = res.data;
          this.province_list = data
        }
      } catch (e) {

      }
    },
    /**
     * 根据省份获取城市
     * @param id
     * @returns {Promise<*>}
     */
    async getCityList(id) {
      try {
        const res = await getCityList(id);
        if (res.code === 0) {
          const data = res.data
          return data
        }
      } catch (e) {

      }
    },
    /**
     * 根据城市获取城区
     * @param id
     * @returns {Promise<*>}
     */
    async getAreaList(id) {
      try {
        const res = await getAreaList(id);
        if (res.code === 0) {
          const data = res.data
          return data
        }
      } catch (e) {

      }
    },
    /**
     * 根据城区获取街道
     * @param id
     * @returns {Promise<*>}
     */
    async getStreetList(id) {
      try {
        const res = await getStreetList(id);
        if (res.code === 0) {
          const data = res.data;
          return data
        }
      } catch (e) {

      }
    },
    choseAdd() { // 选择地址弹层，打开弹层
      this.is_layout = true
      this.is_togeHeight = true
      if (this.$route.query.edit_id !== undefined) {
        this._gotoTop('.proJuli', 0)
      }
    },
    closeAdd: function () { // 关闭弹层
      this.is_togeHeight = false
      this.is_layout = false
    },
    confirmAddress() {
      this.is_togeHeight = false
      this.is_layout = false
      this.userAddress = `${this.Province} ${this.City} ${this.District} ${this.Street}`
      this.province_id = this.province;
      this.city_id = this.city;
      this.area_id = this.district;
      this.street_id = this.street
      let params = {
        area_string: `${this.Province} ${this.City} ${this.District} ${this.Street}`,
        province_id: this.province,
        city_id: this.city,
        area_id: this.district,
        street_id: this.street
      }
      this.$emit('confirmAddress', params);
    },
    _newArr(arr, select_id, type) { //type 1 省2市3区4街道
      if (type === 1) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].province_id === select_id) {
            this.$set(arr[i], 'selected', true)
          } else if (select_id === -1) {
            this.$set(arr[i], 'selected', false)
          }
        }
      } else if (type === 2) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].city_id === select_id) {
            this.$set(arr[i], 'selected', true)
          } else if (select_id === -1) {
            this.$set(arr[i], 'selected', false)
          }
        }
      } else if (type === 3) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].area_id === select_id) {
            this.$set(arr[i], 'selected', true)
          } else if (select_id === -1) {
            this.$set(arr[i], 'selected', false)
          }
        }
      } else if (type === 4) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].street_id === select_id) {
            this.$set(arr[i], 'selected', true)
          } else if (select_id === -1) {
            this.$set(arr[i], 'selected', false)
          }
        }
      } else {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].code === select_id) {
            this.$set(arr[i], 'selected', true)
          } else if (select_id === -1) {
            this.$set(arr[i], 'selected', false)
          }
        }
      }
      return arr

    },
    _gotoTop(info, index) { // 滚动距离 --> 对应class,第几个index
      let proJuliBox = document.querySelector(info)
      let activeBox = document.getElementsByClassName('active')[index]
      let t = activeBox.offsetTop - 67 // 后面的数据，根据页面情况自己调整
      proJuliBox.scrollTo(0, t)
    },

    /**
     * 选择省
     */
    async getProvinceId(code, input, index) { // 点击第一个li
      // console.log('code', code, input, index)
      this.titleIndex = Number
      this.province = code
      this.Province = input // 获取选中的省份
      this.showProvince = false
      this.showCity = true
      this.showDistrict = false
      this.showCityList = await this.getCityList(code)
      // 点击选择当前
      this.province_list.map(a => {
        a.selected = false
      });
      this.province_list[index].selected = true
      // console.log(this.info[index].name) // 点击的省份的名字

      this.province_ac = false // 给第一个nav去掉高亮
      this.city_ac = true // 给第二个nav添加高亮
      this.area_ac = false // 去除第三个li的高亮
      this.two_show = true // 控制第二个nav显示
      // this.City = false // 清除市级和区级nav选项
      // this.District = false // 清除市级和区级nav选项
      this.City = '' // 第二nav置空
      this.District = '' // 第三nav置空
      this.three_show = false // 第三nav隐藏
      this.Street = '' // 第四nav置空
      this.four_show = false // 第四nav隐藏
      this.one_li_index = index
      this._newArr(this.showCityList, -1) // 清除市级高亮
      this.showDeter = false
    },
    //切换到省
    provinceSelected() {
      this.showProvince = true
      this.showCity = false
      this.showDistrict = false
      this.province_ac = true // 给第一个nav添加高亮
      this.city_ac = false // 给第二个nav去除高亮
      this.area_ac = false // 给第三个nav去掉高亮
      this.street_ac = false // 给第四个nav去掉高亮
    },
    /**
     * 选择市
     */
    async getCityId(code, input, index) { // 点击第二个li
      // console.log('id', code, input, 'index', index)
      this.titleIndex = Number
      this.city = code
      this.City = input
      this.showProvince = false
      this.showCity = false
      this.showDistrict = true
      this.showStreet = false
      this.showDistrictList = await this.getAreaList(code)
      // 选择当前添加active
      this.showCityList.map(a => {
        a.selected = false
      })
      this.showCityList[index].selected = true
      this.two_li_Index = index
      this.city_ac = false // 给第二个nav去除高亮
      this.area_ac = true // 给第三个nav添加高亮
      this.three_show = true // 给第三个nav显示
      this.District = '' // 第三nav置空
      this.Street = '' // 第四nav置空
      this.four_show = false // 第四nav隐藏
      this._newArr(this.showDistrictList, -1) // 清除区级高亮
      this.showDeter = false
    },
    /**
     * 切换到市
     */
    citySelected() {
      // console.log('点击了第二个nav')
      this.titleIndex = 2
      this.showProvince = false
      this.showCity = true
      this.showDistrict = false
      this.showStreet = false

      this.province_ac = false // 给第一个nav去掉高亮
      this.city_ac = true // 给第二个nav添加高亮
      this.area_ac = false // 给第三个nav去掉高亮
      this.street_ac = false // 给第四个nav去掉高亮
      if (this.$route.query.edit_id !== undefined) {
        this.$nextTick(() => { // 让li标签回到顶部
          this._gotoTop('.citJuli', 1)
        })
      }
    },
    /**
     * 选择区
     */
    async getDistrictId(code, input, index) {
      this.titleIndex = Number
      this.district = code
      this.District = input
      this.showProvince = false
      this.showCity = false
      this.showDistrict = false
      this.showStreet = true
      this.showStreetList = await this.getStreetList(code)
      // 选择当前添加active
      this.showDistrictList.map(a => {
        a.selected = false
      })
      this.showDistrictList[index].selected = true
      this.two_li_Index = index

      this.area_ac = false // 给第三个nav去除高亮
      this.street_ac = true // 给第三个nav添加高亮
      this.four_show = true // 给第四个nav显示
      this.Street = '' // 第四nav置空
      this._newArr(this.showStreetList, -1) // 清除区级高亮
      this.showDeter = false
    },
    /**
     * 选择街道
     */
    async getStreetId(code, input, index) {
      this.titleIndex = Number
      this.street = code
      this.Street = input
      // 选择当前添加active
      this.showStreetList.map(a => {
        a.selected = false
      });
      this.showStreetList[index].selected = true
      // 选取市区选项之后关闭弹层
      this.province_ac = false // 给第一个nav去掉高亮
      this.showDeter = true
    },
    /**
     * 切换到区
     */
    districtSelected() { // 第三个选择
      // console.log('点击了第三个nav')
      this.showProvince = false
      this.showCity = false
      this.showDistrict = true
      this.showStreet = false
      this.province_ac = false // 给第一个nav去掉高亮
      this.city_ac = false // 给第二个nav去掉高亮
      this.area_ac = true // 给第三个nav添加高亮
      this.street_ac = false // 给第四个nav去掉高亮
      if (this.$route.query.edit_id !== undefined) {
        this.$nextTick(() => { // 让li标签回到顶部
          this._gotoTop('.disJuli', 2)
        })
      }
    },
    /**
     * 切换到街道
     */
    streetSelected() {
      this.showProvince = false
      this.showCity = false
      this.showDistrict = false
      this.showStreet = true
      this.province_ac = false // 给第一个nav去掉高亮
      this.city_ac = false // 给第二个nav去掉高亮
      this.area_ac = false // 给第三个nav去掉高亮
      this.street_ac = true // 给第四个nav添加高亮
      if (this.$route.query.edit_id !== undefined) {
        this.$nextTick(() => { // 让li标签回到顶部
          this._gotoTop('.streetLi', 3)
        })
      }
    },
  },
}
</script>
<style lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.select-container {

  /* 地址选择弹层 */

  .address {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 121;
    background: #fff;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height .5s;
  }

  .togeHeight {
    height: 7.7rem;
  }

  .layout {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 120;
    opacity: 0;
    transition: all .5s;
    background: @color-dark-grey;
    visibility: hidden;
  }

  .layoutBg {
    opacity: .7;
    visibility: visible;
  }

  .area {
    float: left;
    display: inline-block;
    font-size: 0.28rem;
    height: .82rem;
    line-height: 0.82rem;
    margin-left: 0.3rem;
    color: @color-dark-grey;
    max-width: calc(100% - 83%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .addList {
    padding: 0 0.3rem;
    font-size: 0.3rem;
    line-height: 0.67rem;
    color: #262e31;
  }

  /* 修改的格式 */

  .address {
    .address_slide_head {
      position: relative;
      overflow: hidden;
      height: 0.8rem;
      line-height: 0.8rem;
      text-align: center;
      background: @color-white;
      .sc(0.28rem, @color-dark-grey)
    }

    ul {
      height: calc(100% - 1.62rem);
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      .active {
        color: var(--main-color);

        span::after {
          content: '';
          width: .4rem;
          height: .2rem;
          background-repeat: no-repeat;
          background-size: .2rem .13rem;
          background-position: left .16rem center;
          display: inline-block;
        }
      }

      li {
        list-style: none;
      }
      .w32{
        width: 0.32rem
      }
    }

    .title {
      height: .82rem;

      .ac-color {

        color: var(--main-color);
        border-bottom: 0.04rem solid var(--main-color);
      }
    }


    .determine {
      display: inline-block;
      width: .75rem;
      text-align: right;
      float: right;
      height: .82rem;
      line-height: .82rem;
      margin-right: .3rem;
      color: var(--main-color);
      font-size: .28rem;
    }
  }
}


</style>
